@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
}

img {
  max-height: calc(100vh - 64px);
}

#root {
  height: 100%;
}

